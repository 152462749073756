import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

import messages from "../components/messages"

// const IndexPage = () => (

class SamplesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openFaqId: "",
      openFaqs: [],
    }
  }

  handleChange(e) {
    // console.log(`${e.target.name}: ${e.target.value}`)
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    this.setState({ messages: messages })
  }

  faqArrow(faqId) {
    // console.log("faqArrow: ", faqId)
    const openFaqs = this.state.openFaqs
    // this.state.openFaqId !== faqId
    if (openFaqs.indexOf(faqId) === -1) {
      // console.log("faqArrow nomatch: ", faqId)
      return <i className="material-icons faqIcon">keyboard_arrow_down</i>
    } else {
      // console.log("faqArrow match: ", faqId)
      return <i className="material-icons faqIcon tgrey3">keyboard_arrow_up</i>
    }
  }

  faqToggle(faqId) {
    const openFaqs = this.state.openFaqs
    if (openFaqs.indexOf(faqId) === -1) {
      // console.log("faqId nomatch: ", faqId)
      openFaqs.push(faqId)
    } else {
      // console.log("faqId match: ", faqId)
      openFaqs.splice(openFaqs.indexOf(faqId), 1)
    }
    this.setState({
      openFaqs: openFaqs,
    })
  }
  removeDuplicates(msg) {
    let newText = msg.replace(/\s+/g, " ")
    newText = newText.replace(/§/g, "\n")
    return newText
    // return msg.replace(/\s+/g, " ")
    // return msg.replace(/\s/g, "")
    // return msg.replace(/\s\s/g, " ")
  }

  genRandomId(length) {
    const fixedLength = 10
    let result = ""
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    for (var i = 0; i < fixedLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  is7BitString(text) {
    // https://stackoverflow.com/questions/12673120/how-to-detect-non-gsm-7-bit-alphabet-characters-in-input-field
    // regex containing all chars of 7bit alphabet
    const regexp = new RegExp(
      "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
    )
    //test if chat input text contains non 7bit characters
    let is7Bit = regexp.test(text)
    // console.log("is7bit: ", is7Bit)
    // console.log("containsNonLatinCodepoints: ", this.containsNonLatinCodepoints(text));
    return is7Bit
    // return regexp.test(text);
  }

  getMsgParts(msgText, cardId) {
    // messageCharCount: 23,
    // availableMsgChars: 160,
    // msgSmsParts: 1,
    // console.log("cardId: ", cardId)
    const msg = document.getElementById(cardId)
    if (msg) {
      const messageText = msg.innerHTML
      //   console.log("innerHTML MSG LENGTH: ", messageText.length)
    } else {
      //   console.log("no innerHTML")
    }

    // const msgLength = broadcastMessage.length + 18; //18 characters === " Text STOP to end."
    const msgLength = msgText.length
    const sms1PartLength = 160
    const smsMultiPartLength = 153
    const smsUnicode1PartLength = 70
    const smsUnicodeMultiPartLength = 67
    let availableChars = 160
    let msgParts = 1
    let is7Bit = this.is7BitString(msgText)
    // if 7bit
    if (is7Bit) {
      if (msgLength > sms1PartLength) {
        msgParts = Math.ceil(msgLength / smsMultiPartLength)
        availableChars = msgParts * smsMultiPartLength
      }
      // else unicode msg
    } else {
      if (msgLength > smsUnicode1PartLength) {
        msgParts = Math.ceil(msgLength / smsUnicodeMultiPartLength)
        availableChars = msgParts * smsUnicodeMultiPartLength
      } else {
        availableChars = smsUnicode1PartLength
      }
    }
    // console.log("messageCharCount: ", msgLength);
    // console.log("availableMsgChars: ", availableChars);
    // console.log("msgSmsParts: ", msgParts);
    return {
      is7Bit: is7Bit,
      messageCharCount: msgLength,
      availableMsgChars: availableChars,
      msgSmsParts: msgParts,
    }
    // this.setState({
    //   is7Bit: is7Bit,
    //   messageCharCount: msgLength,
    //   availableMsgChars: availableChars,
    //   msgSmsParts: msgParts,
    // })
  }

  toggleSection(index) {
    let messages = this.state.messages
    console.log("isOpen: ", messages[index].isOpen)
    const isOpen = messages[index].isOpen
    messages[index].isOpen = !isOpen
    this.setState({ messages: messages })
    // messages[index].isOpen = !isOpen
  }

  render() {
    return (
      <Layout>
        <SEO title="Free SMS Samples" />
        {/* HERO */}
        <div className="hero1">
          <div className="content taCenter">
            <h1>Message Samples</h1>
            {/* <p>A Fully Managed SMS Marketing Service.</p> */}
            {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            {/* <hr /> */}
            {/* <p>A Fully Managed SMS Marketing Service</p> */}
            {/* <p>
          Have a text message you want to broadcast out?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
            <p>
              A free resource to help you craft
              <br /> the perfect text message{" "}
              <span role="img" aria-label="party-popper">
                🎉
              </span>
            </p>
            {/* <hr />
                <p>Contact us and we'll handle it for you!</p>
                <p>
                  <input
                    type="email"
                    name=""
                    placeholder="your.name@email.com"
                  />
                  <br />
                  <button className="">Get Started</button>
                  <button className="">Yes, Contact Me</button>
                </p> */}
          </div>
        </div>

        {/* MESSAGE SAMPLES */}
        <div className="hero5">
          <div className="content">
            <div className="card">
              <h3>Message Tips</h3>
              <br />
              Keep messages short and to the point for the best engagement.
              <br />
              <br />
              For best results, include:
              <ul>
                <li>
                  A Call to Action <br />
                  <span className="tgrey3">
                    ("Call now", "Limited time offer", etc)
                  </span>
                </li>
                <li>
                  An exclusive value or benefit to your customers <br />
                  <span className="tgrey3">
                    ("20% Off", "Buy two, get third free", etc)
                  </span>
                </li>
                {/* <li>
                  Keep messages short and to the point for the best engagement.
                </li> */}
              </ul>
              {/* Keep messages short and to the point for the best engagement. */}
              For compliance, include:
              <ul>
                <li>
                  Your Organization Name.
                  <br />
                  <span className="tgrey3">
                    "Hi this is Jessica's Salon..."
                  </span>
                </li>
                <li>
                  Contact information: <br />
                  <span className="tgrey3">
                    A Phone number, Address, or Website with more info.
                  </span>
                </li>
                <li>
                  An opt out mechanism <br />
                  <span className="tgrey3">
                    Provided automatically with VantageSMS!
                  </span>
                </li>
              </ul>
            </div>
            {/* <p className="taLeft">
              Remeber to include the following in your message:
              <br />- Identify yourself by including: Organization Name + Phone
              number OR Address
              <br />- An opt out mechanism (provided automatically with
              VantageSMS)
              <br />- A Call to Action
              <br />
              Keep messages short and to the point for the best engagement.
            </p> */}
            {/* <h1>FAQ</h1>
            <h2>
              Can't find an answer? Please reach out to us at:
              <br />
              <a href="mailto:hello@vantagesms.com">hello@vantagesms.com</a>
            </h2>
            <br /> */}
            {/* <h3 className="pr40">Message Categories</h3> */}
            {this.state.messages &&
              this.state.messages.map((category, index) => {
                const cardId = this.genRandomId()
                return (
                  <React.Fragment key={"title" + index + cardId}>
                    <div
                      key={"title" + index + cardId}
                      className={`card mb22 pointer ${
                        category.isOpen ? "bcblue" : ""
                      }`}
                      onClick={() => this.toggleSection(index)}
                    >
                      <h3 className="pr40">
                        {category.isOpen && (
                          <i className="material-icons faqIcon">
                            keyboard_arrow_up
                          </i>
                        )}
                        {!category.isOpen && (
                          <i className="material-icons faqIcon">
                            keyboard_arrow_down
                          </i>
                        )}
                        {category.title}
                      </h3>
                    </div>
                    {category.isOpen && (
                      <div
                        className="colFlexContainer"
                        key={"content" + index + cardId}
                      >
                        <div className="colFlex">
                          {category.cards.map((card, index) => {
                            if (index % 2 === 0) {
                              return (
                                <div
                                  className="card columnCard"
                                  key={"even" + index + cardId}
                                >
                                  <p>{this.removeDuplicates(card.body)}</p>
                                  <p className="columnCardStats">
                                    {
                                      this.getMsgParts(
                                        this.removeDuplicates(card.body),
                                        cardId
                                      ).msgSmsParts
                                    }{" "}
                                    SMS Part
                                    {this.getMsgParts(
                                      this.removeDuplicates(card.body),
                                      cardId
                                    ).msgSmsParts > 1
                                      ? "s"
                                      : ""}
                                    &nbsp;&middot;{" "}
                                    {this.removeDuplicates(card.body).length}{" "}
                                    chars
                                  </p>
                                </div>
                              )
                            }
                          })}
                        </div>
                        <div className="colFlex">
                          {category.cards.map((card, index) => {
                            if (index % 2 === 1) {
                              const cardId = this.genRandomId()
                              return (
                                <div
                                  className="card columnCard"
                                  key={"odd" + index + cardId}
                                >
                                  <p>{this.removeDuplicates(card.body)}</p>
                                  <p className="columnCardStats">
                                    {
                                      this.getMsgParts(
                                        this.removeDuplicates(card.body),
                                        cardId
                                      ).msgSmsParts
                                    }{" "}
                                    SMS Part
                                    {this.getMsgParts(
                                      this.removeDuplicates(card.body),
                                      cardId
                                    ).msgSmsParts > 1
                                      ? "s"
                                      : ""}
                                    &nbsp;&middot;{" "}
                                    {this.removeDuplicates(card.body).length}{" "}
                                    chars
                                  </p>
                                </div>
                              )
                            }
                          })}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
          </div>
        </div>
        {/* </div> */}

        {/* HERO FOOTER*/}
        <div className="heroFooter taCenter">
          <div className="content">
            <h1>Grow Your Sales</h1>
            {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
            <p>Reach the customers that matter most, your own.</p>
            <EmailCapture />
            {/* <p style={{ padding: "0px 20px" }}>
              <input type="email" name="" placeholder="your.name@email.com" />
              <br />
              <button className="">Get Started</button>
              <button className="">Yes, Contact Me</button>
              <button className="">Yes, Contact Me to Get Started</button>
            </p> */}
          </div>
        </div>
      </Layout>
    )
  }
}

// module.exports = {
//   IndexPage: IndexPage,
// }

export default SamplesPage
