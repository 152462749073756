const messages = [
  //   {
  //     title: "Welcome",
  //     cards: [
  //       {
  //         body: `Hi there 👋,

  //         This site is a free resource provided by VantageSMS.com to help you craft the perfect text message.`,
  //       },
  //       {
  //         body: `Remember to say who you are, include a method to unsubscribe, and always follow local laws regarding spam compliance. 👍`,
  //       },
  //       {
  //         body: `For any questions, please contact us at support@vantagesms.com 📫`,
  //       },
  //       {
  //         body: `For pricing information, check out our pricing page.`,
  //       },
  //     ],
  //   },
  {
    title: "Sales",
    isOpen: true,
    cards: [
      {
        body: `Hi, this is Jane from NuCo Textiles.
        §§
        We have a 30% OFF SALE for all paper products on now!
        §§
        Reply for more info or Call 647-555-1234.
        §§
        Text STOP to end.`,
      },
      {
        body: `This is Erin from NuCo Phones at 123 Main St. Check out our specials this month, up to 30% OFF:
        §§
        Latest iPhone: $699
        §§
        Latest iPad: $599
        §§
        Latest Android Phone: $499
        §§
        Reply for more info.
        §§
        Text STOP to end.`,
      },
      {
        body: `Dwight Auto is having a BUY 3, GET 1 FREE TIRE SALE.
        §§
        On now, until the end of June.
        §§
        Visit us at 321 Front St. for pricing and more info.
        §§
        Text STOP to end.`,
      },
      {
        body: `Sweet Sale Alert! All ice cream cakes are 50% OFF this weekend only at NuCo Bakery.
        §§
        Call 1-800-555-1234 or Visit us at 321 Front St. to get your cake and eat it too.
        §§
        Text STOP to end.`,
      },
      //   {
      //     body: `xxxxx`,
      //   },
      //   {
      //     body: `xxxxx`,
      //   },
    ],
  },
  {
    title: "Promotions",
    cards: [
      {
        body: `Hi [FIRST_NAME], this is Jim's Pizza at 123 Main St.
        §§
        THIS WEEK ONLY: buy two large pizzas, get third one free!
        §§
        Text STOP to end.`,
      },
      {
        body: `Hi this is Jim from NuCo Sandwiches at 123 Main St:
        §§
        Here's a special coupon code to get 10% OFF your next order this month:
        §§
        TENOFF
        §§
        Text STOP to end.`,
      },
      {
        body: `Keep the family happy this summer with free entry for kids to the Nuco Theme Park from June to August.
        §§
        Visit us at 321 Main St, East of the 401, to let the summer fun begin!
        §§
        Text STOP to end.`,
      },
      {
        body: `The Nuco 10km Walk-4-Wildlife charity run is on next month!
        §§
        Sign up today at example.com/walk or Call 1-800-555-1234 for more info.
        §§
        All money raised will go to support local animal shelters.
        §§
        Text STOP to end.`,
      },
      {
        body: `Hi volunteers! This is Jim from the NuCo Party. We need your support for the rally on Sunday at 125 Front St.
        §§
        If you can help, sign up at: example.com or reply to this message.
        §§
        Together, we can all make a difference.
        §§
        Text STOP to end.`,
      },
      {
        body: `Hi [FIRST_NAME], this is Pam your agent, a new listing has just become available that you might like:
        §§
        123 Front St: 4 BR, 3 BA, 4,500 sq. ft. $650k - Photos: example.com/pics
        §§
        Call me at 123-555-6789 if you're interested.
        §§
        Text STOP to end.
        `,
      },
    ],
  },
  {
    title: "Engagement",
    cards: [
      {
        body: `Hi this is Pam from NuCo Hair Salon.
        §§
        Stay cool this summer with a fresh new haircut.
        §§
        Call 1-800-555-123 to book your next appointment.
        §§
        Text STOP to end.`,
      },
      {
        body: `NuCo Bank is now open until 8pm on weekdays!
        §§
        Making it easier than ever to setup that investment plan you've been meaning to.
        §§
        Call 1-800-555-123 or visit us at 123 Main St to book your next appointment.
        §§
        Text STOP to end.`,
      },
      {
        body: `Only 45 days until the tax filing deadline!
        §§
        Just a friendly tax countdown reminder from Nuco Accountants.
        §§
        Call 1-800-555-123 to schedule your next appointment.
        §§
        Text STOP to end.`,
      },
      {
        body: `1 in 8 Canadians will go hungry tonight.
        §§
        Find out how you can help by Calling 123-555-4567 or visit example.com/help
        §§
        This message was brought to you by NuCo Helps.
        §§
        We thank you for your support and ask that you give generously to help those in need.
        §§
        Text STOP to end.`,
      },
      //   {
      //     body: `xxxxx`,
      //   },
      //   {
      //     body: `xxxxx`,
      //   },
    ],
  },
  {
    title: "Holidays",
    cards: [
      {
        body: `NuCo would like to extend our best wishes to all our valued customers for a wonderful Holiday Season this December with a special gift.
        §§
        Purchase a NuCo 100$ gift card and Receive 120$ value.
        §§
        Call 123-555-4567 or visit us at 321 Main St for more info.
        §§
        Text STOP to end.`,
      },
      {
        body: `Happy New Year from Nuco Systems at 321 Main St!
        §§
        To celebrate, we're giving you 10% OFF your next purchase for the month of January!
        §§
        Hurry in to take advantage of this limited time offer. Call 905-660-4367 to book your next appointment.
        §§
        Text STOP to end.`,
      },
      {
        body: `May is for Moms!
        §§
        Show Mom your appreciation this month with a full service gift card and receive a FREE personal conditioner treatment during the month of May.
        §§
        Call 123-555-4567 or visit example.com/gifts for more info.
        §§
        Text STOP to end.`,
      },
      {
        body: `Happy Canada Day from Nuco Paints!
        §§
        Celebrate with us this summer and receive a free paint brush with your next purchase.
        §§
        Call 123-555-4567 or visit us at 321 First St for more info.
        §§
        Text STOP to end.`,
      },
      //   {
      //     body: `xxxxx`,
      //   },
      //   {
      //     body: `xxxxx`,
      //   },
    ],
  },
  // {
  //     title: "AAAAA",
  //     cards: [
  //         {
  //             body:`BBBBB`
  //         },
  //     ]
  // },
]
export default messages
